html, body {
  width: 100%;
  height: 100vh;
  margin: 0px;
  padding: 0px;
}
.mapbox-map {
  width: 100%;
  height: 100vh;
}
.sidebar-overlay {
  background-color: #fff;
  max-width: 500px;
  position:fixed;
  z-index: 20;
  left: 10px;
  top: 10px;
}
.searchbar {
  width: 500px;
  background-color: #fff;
}
.search-results {
  display: block;
  max-height: 90vh;
  overflow-y: auto;

}
.nearby-results {
  background-color: #fff;
  padding: 10px;
  width: calc(100vw - 520px);
  position:fixed;
  bottom: 0px;
  height: 300px;
  right: 0px;
  z-index: 200;
  margin-bottom: 10px;
}
.marker {
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}
.google i{
  color:rgb(214, 42, 42);
}

.whatsapp i{
  color:green;
}
.instagram i{
  color:red;
}
.youtube {
  color:red;
}
.twitter ,.facebook{
  color:rgb(11, 126, 235);
}